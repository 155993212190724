import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/generic-entropy.appspot.com/o/icp-docs%2Ficp-logo.png?alt=media&token=9430d051-c333-4f8d-880e-1041331e07ac"
          className="App-logo"
          alt="logo"
        />
        <h1>OfficeX</h1>
        <p style={{ marginTop: "-30px" }}>
          Free Unlimited Workspace owned by You
        </p>
        <a
          className="App-link"
          href="https://tinyurl.com/ofx-docs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Docs
        </a>
        <a
          className="App-link"
          href="https://tinyurl.com/ofx-sheets"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sheets
        </a>
        <a
          className="App-link"
          href="https://tinyurl.com/ofx-slides"
          target="_blank"
          rel="noopener noreferrer"
        >
          Slides
        </a>
        <a
          className="App-link"
          href="https://tinyurl.com/ofx-storage"
          target="_blank"
          rel="noopener noreferrer"
        >
          Drive
        </a>
        <a
          className="App-link"
          href="https://tinyurl.com/ofx-icplive"
          target="_blank"
          rel="noopener noreferrer"
        >
          ICP Demo
        </a>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <a
            href="https://docs.google.com/presentation/d/1yW_kvzW4X8KxtY7SUYwcvdnlzNiVcMJ8KUmLSmfnrQo/edit?usp=sharing"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "gray",
              fontSize: "0.9rem",
              fontFamily: "sans-serif",
            }}
          >
            Alpha v0.1
          </a>
          <span style={{ color: "gray", margin: "0px 10px" }}>|</span>
          <a
            href="https://docs.google.com/presentation/d/1yW_kvzW4X8KxtY7SUYwcvdnlzNiVcMJ8KUmLSmfnrQo/edit?usp=sharing"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "gray",
              fontSize: "0.9rem",
              fontFamily: "sans-serif",
            }}
          >
            About
          </a>
          <span style={{ color: "gray", margin: "0px 10px" }}>|</span>
          <a
            href="https://t.me/Oxterran"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "gray",
              fontSize: "0.9rem",
              fontFamily: "sans-serif",
            }}
          >
            Contact Us
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
